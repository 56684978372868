/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, useRef } from 'react';
import { AuthState, AuthUserState } from '../../../redux/features/authSlice';
import { useAppSelector } from '../../../redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'common/router-name';

const CallNotification: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const authUser = useAppSelector(state => state.auth.user) as AuthUserState;
  const [, setMessages] = useState<string[]>([]);
  const [call, setCall] = useState<any>(null);
  const auth = useAppSelector(state => state.auth) as AuthState;
  const wsRef = useRef<WebSocket | null>(null); // WebSocket instance reference
  const isConnected = useRef<boolean>(false); // To track connection status

  useEffect(() => {
    const socketUrl = `${process.env.REACT_APP_SOCKET_DEV}=${auth.token}`;

    if (auth.user?.user_type !== 'DS') return;
    if (!isConnected.current) {
      wsRef.current = new WebSocket(socketUrl);
      isConnected.current = true;

      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        isConnected.current = true;
      };

      wsRef.current.onmessage = e => {
        const data = JSON.parse(e.data);
        setCall(data);
        setMessages((prevMessages: any) => [...prevMessages, data]);
      };

      wsRef.current.onerror = error => {
        console.error('WebSocket error:', error);
        isConnected.current = false;
      };

      wsRef.current.onclose = () => {
        console.log('WebSocket disconnected');
        isConnected.current = false;
      };
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        isConnected.current = false; // Ensure connection status is reset
      }
    };
  }, []);

  return (
    <>
      {authUser.user_type === 'DS' &&
        (call?.type === 'existing_calls' || call?.type === 'new_call') &&
        call?.call_data &&
        authUser.accept_calls &&
        pathname !== Routes.PendingUserQue && (
          <p
            className="p-3 fw-semibold cursor-pointer"
            style={{
              color: '#ffffff',
              backgroundColor: '#F48B8B',
              marginTop: '-32px',
              marginLeft: '-40px',
              marginRight: '-40px',
              textAlign: 'center',
              transition: '1s'
            }}
            onClick={() => navigate(Routes.PendingUserQue)}
          >
            INCOMING CALL - YOU WILL BE REDIRECTED TO THE USER
          </p>
        )}
      {/* <div>
        <h2>WebSocket Chat (TypeScript)</h2>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            height: '200px',
            overflowY: 'scroll'
          }}
        >
          <h4>Messages:</h4>
          {messages.length > 0 ? (
            messages.map((msg, index) => <div key={index}>{msg}</div>)
          ) : (
            <p>No messages yet...</p>
          )}
        </div>
      </div> */}
    </>
  );
};

export default CallNotification;
