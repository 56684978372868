/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/base/AdvanceTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Dropdown, Row, Modal } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import { ChangeEvent, useMemo, useState } from 'react';
import Rating from 'components/base/Rating';
import {
  faChevronRight,
  faShareSquare,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';
import { Routes } from 'common/router-name';
import useDashboardHook from './useDashboardHook';
import { EventProps } from './dashboard.interface';
import { Link } from 'react-router-dom';
import Button from 'components/base/Button';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { PageSize } from 'common/variables';
import { selectEventType } from 'helpers/utils';
import { format, parseISO } from 'date-fns';

const EventsTable = () => {
  const { EventDetailsPage, UserEventsProfile } = Routes;
  const domain = process.env.REACT_APP_URL;

  const [url, setURL] = useState('');
  const [copied, setCopied] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCopied(false);
    setURL('');
  };
  const handleShow = (url: string) => {
    setShow(true);
    setURL(url);
  };

  const handleCopy = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const columns: ColumnDef<EventProps>[] = useMemo(
    () => [
      {
        id: 'productImage',
        accessorKey: '',
        meta: { cellProps: { className: 'py-0' } },
        enableSorting: false
      },
      {
        accessorKey: 'event',
        header: 'Event',
        cell: ({ row: { original } }) => {
          const { event_type, created_at, event_id } = original;
          return (
            <Link
              to={`${EventDetailsPage}/${event_id}`}
              className="d-flex align-items-center fw-semibold"
            >
              {`${event_type.slice(0, 46)}${
                event_type.length > 46 ? '...' : ''
              }`}{' '}
              {created_at &&
                ` on ${format(parseISO(created_at), 'MM/dd/yyyy HH:MM')}`}
            </Link>
          );
        },
        enableSorting: true,
        meta: {
          headerProps: { style: { minWidth: 200 }, className: 'py-2' }
        }
      },
      {
        accessorKey: 'name',
        header: 'User',
        cell: ({ row: { original } }) => {
          const { first_name, last_name, email } = original;
          return (
            <Link
              to={`${UserEventsProfile}/${encodeURIComponent(email)}`}
              className="d-flex align-items-center fw-semibold"
            >
              {first_name} {last_name}
            </Link>
          );
        },
        meta: {
          headerProps: { style: { minWidth: 200 } }
        }
      },
      {
        accessorKey: 'organization',
        header: 'ORGANIZATION',
        cell: ({ row: { original } }) => {
          const { organization } = original;
          return <h6 className="mb-0 text-body">{organization ?? 'N/A'}</h6>;
        },
        meta: {
          headerProps: { style: { minWidth: 100 } }
        }
      },
      {
        accessorKey: 'phone',
        header: 'PHONE NUMBER',
        cell: ({ row: { original } }) => {
          const { phone_no } = original;
          return <h6 className="mb-0 text-body">{phone_no ?? 'N/A'}</h6>;
        },
        meta: {
          headerProps: { style: { minWidth: 100 } }
        }
      },
      {
        accessorKey: 'rating',
        header: 'RATING',
        cell: ({ row: { original } }) => {
          const { rating } = original;
          return (
            <Rating iconClass="fs-10" readonly initialValue={rating ?? 0} />
          );
        },
        meta: {
          headerProps: { style: { minWidth: 110 } }
        }
      },
      {
        accessorKey: 'review',
        header: 'DISPATCHER COMMENTS',
        cell: ({ row: { original } }) => {
          const { dispatcher_comment } = original;
          return (
            <p className="fs--1 fw-semibold text-body-highlight mb-0 line-clamp-3">
              {dispatcher_comment ?? 'N/A'}
            </p>
          );
        },
        meta: {
          headerProps: { style: { minWidth: 250 } }
        }
      },
      {
        accessorKey: '',
        header: 'TAGS',
        cell: ({ row: { original } }) => {
          const { tag } = original;
          return (
            // <Badge
            //   // bg={badgeBg}
            //   variant="phoenix"
            //   iconPosition="end"
            //   className="fs-10"
            //   // icon={<FeatherIcon icon={icon} size={12} className="ms-1" />}
            // >
            //   {tag ?? 'N/A'}
            // </Badge>
            <>{tag ?? 'N/A'}</>
          );
        },
        meta: {
          headerProps: { className: 'ps-5', style: { minWidth: 120 } },
          cellProps: { className: 'ps-5' }
        }
      },
      {
        accessorKey: 'time',
        header: 'TIME',
        cell: ({ row: { original } }) => {
          const { created_at } = original;
          return (
            <h6 className="text-body-highlight mb-0">
              {formatTimestamp(created_at)}
            </h6>
          );
        },
        meta: {
          headerProps: { className: 'text-end', style: { minWidth: 100 } },
          cellProps: { className: 'text-end white-space-nowrap' }
        }
      },
      {
        accessorKey: 'action',
        enableSorting: false,
        header: '',
        cell: ({ row: { original } }) => {
          const { event_id } = original;
          return (
            <Button
              title="copy link"
              variant="phoenix-secondary"
              className="fs-10"
              size="sm"
              onClick={() =>
                handleShow(`${domain}${EventDetailsPage}/${event_id}`)
              }
            >
              <FontAwesomeIcon icon={faShareSquare} />
            </Button>
          );
        },
        meta: {
          cellProps: { className: 'text-end' }
        }
      }
    ],
    []
  );

  const {
    eventList,
    isLoadingeventList,
    formatTimestamp,
    navigate,
    search,
    setSearch,
    pagination,
    setPagination,
    filter,
    setfilter
  } = useDashboardHook(null);

  const table = useAdvanceTable({
    data: eventList?.results ?? [],
    columns,
    pageSize: PageSize,
    pagination: true,
    selection: true,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination,
    pageCount: eventList?.pages,
    manualPagination: true,
    state: {
      pagination
    }
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim() ?? '');
  };

  return (
    <>
      <AdvanceTableProvider {...table}>
        <Row className="align-items-end justify-content-between pb-5 g-3">
          <Col xs="auto">
            <h3>Our users</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Gather insight on user level data
            </p>
          </Col>
          <Col xs={12} md="auto">
            <Row className="g-2 gy-3">
              <Col xs="auto" className="d-flex gap-2">
                <Dropdown
                  onSelect={value =>
                    setfilter({ ...filter, keyValue: value ?? '1' })
                  }
                >
                  <Dropdown.Toggle
                    variant="phoenix-secondary"
                    size="sm"
                    className="bg-body-emphasis bg-body-hover dropdown-caret-none"
                    title="All User"
                  >
                    {selectEventType(filter.keyValue)} &nbsp;
                    <FontAwesomeIcon icon={faChevronRight} className="10" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end" className="p-0">
                    <Dropdown.Item
                      eventKey="1"
                      active={filter.keyValue === '1' && filter.isActive}
                    >
                      All users
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0" />
                    <Dropdown.Item
                      eventKey="2"
                      active={filter.keyValue === '2' && filter.isActive}
                    >
                      Phone number
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0" />
                    <Dropdown.Item
                      eventKey="3"
                      active={filter.keyValue === '3' && filter.isActive}
                    >
                      Organzation
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0" />
                    <Dropdown.Item
                      eventKey="4"
                      active={filter.keyValue === '4' && filter.isActive}
                    >
                      City
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0" />
                    <Dropdown.Item
                      eventKey="5"
                      active={filter.keyValue === '5' && filter.isActive}
                    >
                      State
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <SearchBox
                  placeholder="Search..."
                  size="sm"
                  value={search}
                  onChange={handleSearchInputChange}
                />
                <Button
                  type="button"
                  variant="phoenix-primary"
                  size="sm"
                  onClick={() =>
                    navigate(`${Routes.FilteredUserEvents}?q=${search}`)
                  }
                  title="Search"
                  disabled={!search}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="10" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <AdvanceTable
          tableProps={{
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
          isLoading={isLoadingeventList}
        />
        <AdvanceTableFooter
          navBtn
          showViewAllBtn={false}
          count={eventList?.count}
        />
      </AdvanceTableProvider>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>{url}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={copied ? 'success' : 'primary'}
            onClick={() => handleCopy(url)}
            disabled={copied}
          >
            {copied ? 'Copied' : 'Copy URL'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventsTable;
