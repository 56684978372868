import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ColumnDef } from '@tanstack/react-table';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { TripProps } from '../../user.interface';
import dayjs from 'dayjs';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useDetailsHook from './useDetailsHook';
import { useEffect, useRef } from 'react';

type ReactVideoPlayerRef = ReactPlayer | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DealDetailsMeeting = ({ video }: any) => {
  const {
    videoDetails,
    IsLoadingvideoDetails,
    videoLink,
    setVideoLink,
    videoPagination,
    setVideoPagination
  } = useDetailsHook();

  const playerRef = useRef<ReactVideoPlayerRef>(null);

  const columns: ColumnDef<TripProps>[] = [
    {
      accessorKey: 'file',
      header: 'Title',
      cell: ({ row: { original } }) => {
        const { file, created_at, media_type } = original;
        return (
          <span
            onClick={() => setVideoLink(file)}
            className="fw-semibold cursor-pointer"
          >
            Video {media_type === 'EVR' ? 'Recording ' : 'Upload '}
            {dayjs(created_at).format('DD-MM-YYYY HH:mm')}
          </span>
        );
      },
      enableSorting: false,
      meta: {
        headerProps: { style: { minWidth: 250 }, className: 'py-2' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: videoDetails?.results ?? [],
    columns,
    pageSize: 6,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination: setVideoPagination,
    pageCount: videoDetails?.pages,
    manualPagination: true,
    state: {
      pagination: videoPagination
    }
  });

  useEffect(() => {
    if (playerRef.current) {
      video
        ? playerRef.current?.getInternalPlayer()?.play()
        : playerRef.current?.getInternalPlayer()?.pause();
    }
  }, [video]);

  if (IsLoadingvideoDetails) {
    return <>Loading...</>;
  }

  return (
    <Row className="g-3">
      <Col xl={6} xxl={7} className="d-flex flex-column gap-3">
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
            }}
            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            isLoading={IsLoadingvideoDetails}
          />
          <AdvanceTableFooter
            navBtn
            showViewAllBtn={false}
            count={videoDetails?.count}
          />
        </AdvanceTableProvider>
      </Col>
      <Col xl={6} xxl={5}>
        <div className="w-full d-flex align-items-center justify-content-center player-wrapper">
          <ReactPlayer
            ref={playerRef}
            url={videoLink}
            className="react-player"
            width="100%"
            controls
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload', // Disable download button
                  pip: false
                }
              }
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default DealDetailsMeeting;
