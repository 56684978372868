/* eslint-disable @typescript-eslint/no-explicit-any */
import { PasswordRegex, PhoneWithCountryRegex } from 'common/regex';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  ChangePasswordProps,
  MemberRoot,
  SettingProps
} from './profile.interface';
import { useQuery } from 'react-query';
import { delete_api, get_api, patch_api, post_api, put_api } from 'apis';
import { ApiRoutes } from 'common/api-routes';
import { toast } from 'react-toastify';
import { Messages } from 'common/router-name';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { authUpdate, logout } from '../../../redux/features/authSlice';
import { initialSessionClose } from '../../../redux/features/sessionClose';

const useProfileHook = () => {
  const sessionData = useAppSelector(state => state.sessionClose.urls);
  const dispatch = useAppDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const [setting, setSetting] = useState<SettingProps | null>(null);
  const personalInfoFormRef = useRef<any>(null);
  const [personalInfo, setPersonalInfo] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    phone_no: ''
  });

  // const [changePassword, setChangePassword] = useState<any>({
  //   oldPassword: '',
  //   newPassword: '',
  //   confirmPassword: ''
  // });

  const validationAddProfileSchema = Yup.object().shape({
    first_name: Yup.string().trim().required('First name is required'),
    last_name: Yup.string().trim().required('Last name is required'),
    email: Yup.string().trim(),
    phone_no: Yup.string()
      .trim()
      .required('Phone number is required')
      .matches(PhoneWithCountryRegex, 'Invalid phone number')
  });

  const validationPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .trim()
      .required('Password is required')
      .matches(
        PasswordRegex,
        'Password must contain at least one uppercase, lowercase, number, special character, and be at least 8 characters long'
      ),
    newPassword: Yup.string()
      .trim()
      .required('Password is required')
      .matches(
        PasswordRegex,
        'Password must contain at least one uppercase, lowercase, number, special character, and be at least 8 characters long'
      ),
    confirmPassword: Yup.string()
      .trim()
      .required('Password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialPasswordValues: any = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const closeChildTabs = () => {
    sessionData.forEach((childWindow: any) => {
      if (childWindow && !childWindow.closed) {
        childWindow.close();
      }
    });
    dispatch(initialSessionClose());
  };

  const {
    common,
    getAdminMemberProfile,
    profileImage,
    profileCover,
    changePassword,
    requestToAdmin,
    deleteAccount
  } = ApiRoutes;

  const handleSubmit = async (values: MemberRoot) => {
    setBtnDisable(true);
    const url = `${common}${getAdminMemberProfile}`;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone_no: values.phone_no
    };
    try {
      const res = await patch_api(url, data, dispatch);
      setPersonalInfo({
        first_name: res?.first_name,
        last_name: res?.last_name,
        email: res?.email,
        phone_no: res?.phone_no
      });
      dispatch(authUpdate(data)).then(() => {
        setBtnDisable(false);
      });
      toast.success(Messages.UPDATE_DETAILS);
      setBtnDisable(false);
    } catch (error: any) {
      toast.error(error?.data?.detail);
      toast.error(error?.data?.phone_no[0]);
      setBtnDisable(false);
    }
  };

  const handleChangePasswordSubmit = async (values: ChangePasswordProps) => {
    setBtnDisable(true);
    const url = `${common}${getAdminMemberProfile}${changePassword}`;
    const data = {
      old_password: values.oldPassword,
      new_password: values.newPassword
    };
    try {
      await post_api(url, data, dispatch);
      toast.success(Messages.CHANGE_PASSWORD);
      setBtnDisable(false);
    } catch (error: any) {
      toast.error(error?.data?.old_password.toString());
      toast.error(error?.data?.new_password.toString());
      toast.error(error?.data?.detail);
      setBtnDisable(false);
    } finally {
      closeChildTabs();
      dispatch(logout());
    }
  };

  const handleRequestToAdmin = async () => {
    setBtnDisable(true);
    const url = `${common}${getAdminMemberProfile}${requestToAdmin}`;
    try {
      await put_api(url, null, dispatch);
      toast.success(Messages.REQUEST_TO_ADMIN);
      setBtnDisable(false);
    } catch (error: any) {
      toast.error(error?.data?.detail);
      setBtnDisable(false);
    }
  };

  const handleDeleteAccount = async () => {
    setBtnDisable(true);
    const url = `${common}${deleteAccount}`;
    try {
      delete_api(url, dispatch);
      toast.success(Messages.DELETE_ACCOUNT);
      setBtnDisable(false);
      closeChildTabs();
      dispatch(logout());
    } catch (error: any) {
      toast.error(error?.data?.detail);
      setBtnDisable(false);
    }
  };

  const updateSetting = async (values: SettingProps) => {
    const url = `${common}${getAdminMemberProfile}`;
    const data = (await patch_api(url, values, dispatch)) as MemberRoot;
    return data;
  };

  const changeStateValue = (values: SettingProps) => {
    setSetting(prevSetting => ({
      ...prevSetting,
      ...values
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    changeStateValue({ [name]: checked });
    updateSetting({ [name]: checked });
  };

  const fetchUserProfileData = async (): Promise<MemberRoot | undefined> => {
    try {
      const url = `${common}${getAdminMemberProfile}`;
      const data = (await get_api(url, dispatch)) as MemberRoot;
      setSetting({
        email_notification: data.email_notification,
        login_2fa: data.login_2fa,
        auto_logout: data.auto_logout,
        receive_crimesly_updates: data.receive_crimesly_updates,
        password_change_reminder: data.password_change_reminder
      });
      setPersonalInfo({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_no: data.phone_no
      });
      return data;
    } catch (error: any) {
      toast.error(error?.data?.detail);
      return undefined;
    }
  };

  const {
    data: memberProfile,
    isLoading: IsLoadingMemberProfile,
    isError: IsErrorMemberProfile,
    error: ErrorMemberProfile
  } = useQuery(['user-profile'], async () => await fetchUserProfileData(), {
    refetchOnWindowFocus: false, // Disable refetching on window focus
    retry: false // Disable retries on failure
  });

  const uploadImage = async (values: any) => {
    const formData = new FormData();
    formData.append('image', values);
    const url = `${common}${getAdminMemberProfile}${profileImage}`;
    try {
      const uploadedImage = (await post_api(
        url,
        formData,
        dispatch
      )) as MemberRoot;
      const data = {
        profile_image: uploadedImage.profile_image ?? null
      };
      dispatch(authUpdate(data)).then(() => {
        setBtnDisable(false);
      });
      toast.success('Image uploaded');
    } catch {
      toast.error('Something wents to wrong');
    }
  };

  const uploadCoverImage = async (values: any) => {
    setBtnDisable(true);
    const formData = new FormData();
    formData.append('image', values);
    const url = `${common}${getAdminMemberProfile}${profileCover}`;
    try {
      await post_api(url, formData, dispatch);
      toast.success('Image uploaded');
      setBtnDisable(false);
    } catch (error: any) {
      console.log('error: ', error);
      toast.error('Something wents to wrong');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialAddProfileValues: any = {
    first_name: memberProfile?.first_name,
    last_name: memberProfile?.last_name,
    email: memberProfile?.email,
    phone_no: memberProfile?.phone_no
  };

  return {
    btnDisable,
    validationAddProfileSchema,
    initialAddProfileValues,
    setBtnDisable,
    handleSubmit,
    memberProfile,
    IsLoadingMemberProfile,
    IsErrorMemberProfile,
    ErrorMemberProfile,
    uploadImage,
    uploadCoverImage,
    updateSetting,
    setting,
    setSetting,
    changeStateValue,
    handleCheckboxChange,
    validationPasswordSchema,
    initialPasswordValues,
    handleChangePasswordSubmit,
    personalInfoFormRef,
    personalInfo,
    handleRequestToAdmin,
    handleDeleteAccount
    // changePassword
  };
};

export default useProfileHook;
