export const PrivacyPolicy: string = 'https://www.crimesly.com/privacy-policy';

interface RoutesInterface {
  Dashboard_One: string;
  Dashboard_Two: string;
  FilteredUserEvents: string;
  UserEventsProfile: string;
  MembersRoute: string;
  AddMembersRoute: string;
  Profile: string;
  Auth: string;
  SignUp: string;
  SignIn: string;
  LogoutPage: string;
  TwoFA: string;
  TwoFASignIn: string;
  ForgotPassword: string;
  ResetPassword: string;
  LockPage: string;
  SignOutPage: string;
  UpcommingFeature: string;
  EventDetailsPage: string;
  PendingUserQue: string;
  ChangePassword: string;
  Search: string;
  ZoomCall: string;
}

export const Routes: RoutesInterface = {
  Dashboard_One: '/',
  Dashboard_Two: '/dashboard',
  PendingUserQue: '/pending-user-que',
  FilteredUserEvents: '/filtered-user-events',
  UserEventsProfile: '/filtered-user-events/user-events-profile',
  EventDetailsPage: '/filtered-user-events/user-events-profile/event-details',
  MembersRoute: '/members',
  AddMembersRoute: '/members/add-member',
  Profile: '/profile',
  Auth: '/auth/',
  SignUp: 'sign-up',
  SignIn: 'sign-in',
  TwoFA: 'two-fa',
  TwoFASignIn: 'two-fa-sing-in',
  ForgotPassword: 'forgot-password',
  ResetPassword: 'reset-password',
  LockPage: 'lock-page',
  LogoutPage: 'logout-page',
  UpcommingFeature: 'upcoming-new-feature',
  SignOutPage: '/sign-out',
  ChangePassword: 'change-password',
  Search: '/search',
  ZoomCall: '/session-connect'
};

export const Messages = {
  REGISTER_SUCCESS: 'Sing up sucsuccessfully',
  LOGIN_SUCCESS: 'Signed in successfully!',
  LOGOUT_SUCCESS: 'Sign out successfully',
  OTP_SENT_SUCCESS: 'OTP send successfully',
  OTP_VERIFY_SUCCESS: 'OTP verify successfully',
  RESET_SUCCESS: 'Reset password successfully',
  MEMBER_SUCCESS: 'Member added successfully',
  CHANGE_PASSWORD: 'Password changed successfully',
  REQUEST_TO_ADMIN: 'Request sent to admin',
  DELETE_ACCOUNT: 'Account deleted successfully',
  UPDATE_DETAILS: 'Personal details updated successfully',
  EVENT_NOT_FOUND: 'Event not found',
  COMMENT_SUCCESS: 'Commnet updated succesfully',
  COMMENT_ERROR: 'Commnet not updated',
  ENABLE_CALL_FLAG_SUCCESS: 'Enabling dispatcher calls',
  DISABLE_CALL_FLAG_SUCCESS: 'Disabling dispatcher calls',
  ACCEPT_CALL_FLAG_ERROR: 'Error: Dispatcher calls'
};
