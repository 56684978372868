/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseISO, format } from 'date-fns';
import { useState, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { get_api } from 'apis';
import { PageIndex } from 'common/variables';
import { ApiRoutes } from 'common/api-routes';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { PendingUserQueObjectProps } from './pendintUqeInterface';

function useQueryFunction() {
  return new URLSearchParams(useLocation().search);
}

const usePendingUserQue = () => {
  const query = useQueryFunction();
  const qValue = query.get('q');
  const [callQueueList, setCallQueueList] = useState<PendingUserQueObjectProps>(
    {
      status: 'idle',
      count: 0,
      next: '',
      previous: '',
      pages: 0,
      results: []
    }
  );
  const [searchValue, setSearchValue] = useState<string>(qValue ?? '');
  const [pagination, setPagination] = useState({
    pageIndex: PageIndex,
    pageSize: 20
  });
  const [filter, setfilter] = useState({
    name: 'All Users',
    isActive: true
  });
  const [socketEventType, setsocketEventType] = useState('');
  const [hide, setHide] = useState(true);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [joinedCall, setJoinedCall] = useState({
    userId: '',
    callId: ''
  });
  const dispatch = useAppDispatch();
  const dsCallList = useAppSelector(state => state.dsCall);

  const { common, dispatcherCallList } = ApiRoutes;

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const fetchPendingUserQue = async (
    search: string,
    value1: number,
    value2: number
  ): Promise<PendingUserQueObjectProps> => {
    const url = `${common}${dispatcherCallList}`;
    value1 = value1 + 1;
    const data = (await get_api(
      `${url}?search=${search}&page=${value1}&page_size=${value2}`,
      dispatch
    )) as PendingUserQueObjectProps;
    data.pages = Math.ceil(data.count / 20);
    // const result: PendingUserQueObjectProps = {
    //   count: 2,
    //   next: 'https://api-dev.crimesly.com/api/v1/dashboard/dispatcher-calls/?page=2&page_size=20&search=',
    //   previous: null,
    //   pages: 1,
    //   results: [
    //     {
    //       event: {
    //         event_id: 'eb595be0-8ea0-4907-babd-7dfa03c09ad2',
    //         user: {
    //           first_name: 'ja',
    //           last_name: 'test8',
    //           email: 'jaytest9@gmail.com',
    //           organization: 'McLennan Community College',
    //           location: null
    //         },
    //         navigation_data: null
    //       },
    //       call_id: 'c8d0c3ad-e870-4268-8bc1-7984dcfda419',
    //       session_id: 'dpCB/5QUQTuVjcCyqCJwgw==',
    //       session_status: 'STARTED',
    //       session_name:
    //         'cada8fbe-bfcc-4101-b5b6-d4a96869bc2c-21-09-2024-10-33-47',
    //       call_started_at: '2024-09-21T05:03:50.472069Z',
    //       call_ended_at: null,
    //       duration: null,
    //       dashboard_jwt_token:
    //         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoidDJ1WFRnZjRBN0l5S1UzZmZRcHNaSEhuUTlVdXZvM25WNjV2IiwidHBjIjoiY2FkYThmYmUtYmZjYy00MTAxLWI1YjYtZDRhOTY4NjliYzJjLTIxLTA5LTIwMjQtMTAtMzMtNDciLCJyb2xlX3R5cGUiOjAsInVzZXJfaWRlbnRpdHkiOiJEYXNoYm9hcmQtV2Vic29ja2V0IiwidmVyc2lvbiI6MSwiaWF0IjoxNzI2ODk1NTE0LCJleHAiOjE3MjY5ODE5MTR9.QaxGN8bX_imILrwHkaqERyX0y9ve5pkKAdzlaVGMQAA',
    //       created_at: '2024-09-21T05:03:50.472380Z',
    //       updated_at: '2024-09-21T05:03:50.472386Z'
    //     },
    //     {
    //       event: {
    //         event_id: 'eb595be0-8ea0-4907-babd-7dfa03c09ad2',
    //         user: {
    //           first_name: 'ja',
    //           last_name: 'test8',
    //           email: 'jaytest9@gmail.com',
    //           organization: 'McLennan Community College',
    //           location: null
    //         },
    //         navigation_data: null
    //       },
    //       call_id: 'c8d0c3ad-e870-4268-8bc1-7984dcfda419',
    //       session_id: 'dpCB/5QUQTuVjcCyqCJwgw==',
    //       session_status: 'STARTED',
    //       session_name: '20-09-2024-13-45-50',
    //       call_started_at: '2024-09-21T05:03:50.472069Z',
    //       call_ended_at: null,
    //       duration: null,
    //       dashboard_jwt_token:
    //         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoidDJ1WFRnZjRBN0l5S1UzZmZRcHNaSEhuUTlVdXZvM25WNjV2Iiwicm9sZV90eXBlIjoxLCJ0cGMiOiIyMC0wOS0yMDI0LTEzLTQ1LTUwIiwidmVyc2lvbiI6MSwiaWF0IjoxNzI2ODk1OTQwLCJleHAiOjE3MjY4OTk1NDB9.8YX-1R6vaQyIEd6pWqyJLzKJgzee_I0l1FOB9fTEnac',
    //       created_at: '2024-09-21T05:03:50.472380Z',
    //       updated_at: '2024-09-21T05:03:50.472386Z'
    //     }
    //   ]
    // };
    // setCallQueueList(result);
    // return result;
    setCallQueueList(data);
    return data;
  };

  const {
    data: userQueList,
    isLoading: isLoadinguserQue,
    isError: isErroruserQue,
    error: erroruserQue
  } = useQuery(
    ['user-que-list', searchValue, pagination, hide],
    async () =>
      await fetchPendingUserQue(
        searchValue,
        pagination.pageIndex,
        pagination.pageSize
      ),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const formatTimestamp = (timestamp: string): string => {
    const date = parseISO(timestamp);
    const now = new Date();

    const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    if (differenceInMinutes < 1) {
      return 'just now';
    } else if (differenceInMinutes < 60) {
      return `${Math.floor(differenceInMinutes)} minutes ago`;
    } else if (differenceInMinutes < 1440) {
      return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    } else {
      return format(date, 'MMM dd HH:mm:ss');
    }
  };

  const formatTimestamp2 = (timestamp: string): string => {
    const date = parseISO(timestamp);
    // const now = new Date();

    // const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    // if (differenceInMinutes < 1) {
    //   return 'just now';
    // } else if (differenceInMinutes < 60) {
    //   return `${Math.floor(differenceInMinutes)} minutes ago`;
    // } else if (differenceInMinutes < 1440) {
    //   return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    // } else {
    // }
    return format(date, 'HH:mm:ss');
  };

  return {
    search,
    setSearch,
    sortBy,
    setSortBy,
    sortType,
    setSortType,
    filter,
    setfilter,
    formatTimestamp,
    pagination,
    setPagination,
    qValue,
    userQueList,
    isLoadinguserQue,
    isErroruserQue,
    erroruserQue,
    formatTimestamp2,
    searchValue,
    handleSearchInputChange,
    callQueueList,
    setCallQueueList,
    hide,
    setHide,
    joinedCall,
    setJoinedCall,
    dsCallList,
    socketEventType,
    setsocketEventType
  };
};

export default usePendingUserQue;
