import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import Ecommerce from 'pages/dashboard/ecommerce';
import UserEventsProfileComponent from 'pages/dashboard/user-events-profile';
import EventDetailsComponent from 'pages/dashboard/user-events-profile/event-details';
import FilteredEvents from 'pages/dashboard/filtered-events';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import NewFeature from 'pages/error/NewFeature';
import Showcase from 'pages/Showcase';
import App from 'App';
import { Suspense, lazy } from 'react';
import PhoenixLoader from 'components/common/PhoenixLoader';
import { Routes } from 'common/router-name';
import Protected from 'routes/ProtectedRoutes';
import Restricted from 'routes/RestrictedRoutes';
import RestrictedDsRoutes from 'routes/RestrictedDsRoutes';
import { isAuthenticated } from 'routes/PublicRoutes';
import Members from 'pages/dashboard/member/members';
import AddMember from 'pages/dashboard/member/add-member';
import ProfilePage from 'pages/dashboard/profile/profile';
import SearchPage from 'pages/dashboard/search';
import PendingUserQueComponent from 'pages/dashboard/pending-user-query';
import ZoomCallComponent from 'pages/dashboard/pending-user-query/ZoomCall';
const ChangePasswordComponent = lazy(() => import('auth/change-password'));
const SingUp = lazy(() => import('auth/sign-up'));
const SingIn = lazy(() => import('auth/sign-in'));
const TwoFa = lazy(() => import('auth/tow-fa'));
const TwoFaLogin = lazy(() => import('auth/tow-fa-sing-in'));
const ForgotPasswordComponent = lazy(() => import('auth/forgot-password'));
const ResetPasswordComponent = lazy(() => import('auth/reset-password'));
const LockPageComponent = lazy(() => import('auth/lock-page'));
const LogoutPageComponent = lazy(() => import('auth/logout-page'));
const SignOut = lazy(() => import('auth/sign-out'));

// const routes: RouteObject[] = [
//   {
//     element: <App />,
//     children: [
//       {
//         path: '/',
//         element: (
//           <MainLayoutProvider>
//             <MainLayout />
//           </MainLayoutProvider>
//         ),
//         children: [
//           {
//             index: true,
//             element: <Ecommerce />
//           },
//           {
//             path: '/dashboard',
//             children: [
//               {
//                 path: 'project-management',
//                 element: <ProjectManagement />
//               },
//               {
//                 path: 'crm',
//                 element: <Crm />
//               }
//             ]
//           },
//           {
//             path: '/apps',
//             children: [
//               {
//                 path: 'e-commerce/admin',
//                 children: [
//                   {
//                     path: 'add-product',
//                     element: <AddProduct />
//                   },
//                   {
//                     path: 'products',
//                     element: <Products />
//                   },
//                   {
//                     path: 'customers',
//                     element: <Customers />
//                   },
//                   {
//                     path: 'orders',
//                     element: <Orders />
//                   },
//                   {
//                     path: 'order-details',
//                     element: <OrderDetails />
//                   },
//                   {
//                     path: 'refund',
//                     element: <Refund />
//                   },
//                   {
//                     path: 'customer-details',
//                     element: <CustomerDetails />
//                   }
//                 ]
//               },
//               {
//                 path: 'crm',
//                 children: [
//                   {
//                     path: 'analytics',
//                     element: <Analytics />
//                   },
//                   {
//                     path: 'deals',
//                     element: <Deals />
//                   },
//                   {
//                     path: 'deal-details',
//                     element: <DealDetails />
//                   },
//                   {
//                     path: 'report-details',
//                     element: <ReportDetails />
//                   },
//                   {
//                     path: 'leads',
//                     element: <Leads />
//                   },
//                   {
//                     path: 'lead-details',
//                     element: <LeadDetails />
//                   },
//                   {
//                     path: 'reports',
//                     element: <Reports />
//                   },
//                   {
//                     path: 'add-contact',
//                     element: <AddContact />
//                   }
//                 ]
//               },
//               {
//                 path: 'project-management',
//                 children: [
//                   {
//                     path: 'create-new',
//                     element: <CreateNew />
//                   },
//                   {
//                     path: 'project-list-view',
//                     element: <ProjectListView />
//                   },
//                   {
//                     path: 'project-card-view',
//                     element: <ProjectCardView />
//                   },
//                   {
//                     path: 'project-board-view',
//                     element: <ProjectBoardView />
//                   },
//                   {
//                     path: 'todo-list',
//                     element: <ProjectTodoList />
//                   },
//                   {
//                     path: 'project-details',
//                     element: <ProjectDetails />
//                   }
//                 ]
//               },
//               {
//                 path: 'events',
//                 children: [
//                   {
//                     path: 'create-an-event',
//                     element: <CreateAnEvent />
//                   },
//                   {
//                     path: 'event-detail',
//                     element: <EventDetail />
//                   }
//                 ]
//               },
//               {
//                 path: 'kanban',
//                 children: [
//                   {
//                     path: 'kanban',
//                     element: <Kanban />
//                   },
//                   {
//                     path: 'boards',
//                     element: <Boards />
//                   },
//                   {
//                     path: 'create-board',
//                     element: <CreateBoard />
//                   }
//                 ]
//               },
//               {
//                 path: 'chat',
//                 element: <Chat />,
//                 children: [
//                   {
//                     index: true,
//                     element: <ChatHomepage />
//                   },
//                   {
//                     path: ':userId/conversation',
//                     element: <ChatConversation />
//                   }
//                 ]
//               },
//               {
//                 path: 'social',
//                 children: [
//                   {
//                     path: 'profile',
//                     element: <SocialProfile />
//                   },
//                   {
//                     path: 'settings',
//                     element: <Settings />
//                   },
//                   {
//                     path: 'feed',
//                     element: <Feed />
//                   }
//                 ]
//               },
//               {
//                 path: 'calendar',
//                 element: <Calendar />
//               },
//               {
//                 path: 'email',
//                 children: [
//                   {
//                     path: 'inbox',
//                     element: <Inbox />
//                   },
//                   {
//                     path: 'email-detail',
//                     element: <EmailDetail />
//                   },
//                   {
//                     path: 'compose',
//                     element: <Compose />
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             path: '/pages',
//             children: [
//               {
//                 path: 'starter',
//                 element: <Starter />
//               },
//               {
//                 path: 'faq',
//                 children: [
//                   {
//                     path: 'faq-accordion',
//                     element: <FaqAccordion />
//                   },
//                   {
//                     path: 'faq-tab',
//                     element: <FaqTab />
//                   }
//                 ]
//               },
//               {
//                 path: 'pricing',
//                 children: [
//                   {
//                     path: 'pricing-column',
//                     element: <PricingColumn />
//                   },
//                   {
//                     path: 'pricing-grid',
//                     element: <PricingGrid />
//                   }
//                 ]
//               },
//               {
//                 path: 'notifications',
//                 element: <Notification />
//               },
//               {
//                 path: 'members',
//                 element: <Members />
//               },
//               {
//                 path: 'timeline',
//                 element: <Timeline />
//               },
//               {
//                 path: 'coming-soon',
//                 element: <ComingSoon />
//               },
//               {
//                 path: 'demo',
//                 children: [
//                   {
//                     path: 'vertical-sidenav',
//                     element: <VerticalSidenav />
//                   },
//                   {
//                     path: 'dark-mode',
//                     element: <DarkModeDemo />
//                   },
//                   {
//                     path: 'sidenav-collapse',
//                     element: <SidenavCollapse />
//                   },
//                   {
//                     path: 'darknav',
//                     element: <Darknav />
//                   },
//                   {
//                     path: 'topnav-slim',
//                     element: <TopnavSlim />
//                   },
//                   {
//                     path: 'navbar-top-slim',
//                     element: <NavbarTopSlim />
//                   },
//                   {
//                     path: 'navbar-top',
//                     element: <NavbarTop />
//                   },
//                   {
//                     path: 'horizontal-slim',
//                     element: <NavbarHorizontalSlim />
//                   },
//                   {
//                     path: 'combo-nav',
//                     element: <ComboNav />
//                   },
//                   {
//                     path: 'combo-nav-slim',
//                     element: <ComboNavSlim />
//                   },
//                   {
//                     path: 'dual-nav',
//                     element: <DualNav />
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             path: '/modules',
//             children: [
//               {
//                 path: 'tables',
//                 children: [
//                   {
//                     path: 'basic-tables',
//                     element: <BasicTableExample />
//                   },
//                   {
//                     path: 'advance-tables',
//                     element: <AdvanceTableExample />
//                   }
//                 ]
//               },
//               {
//                 path: 'charts',
//                 children: [
//                   {
//                     path: 'e-charts',
//                     element: <ECharts />
//                   },
//                   {
//                     path: 'gantt-chart',
//                     element: <GanttChart />
//                   }
//                 ]
//               },
//               {
//                 path: 'icons',
//                 children: [
//                   {
//                     path: 'font-awesome',
//                     element: (
//                       <Suspense fallback={<PhoenixLoader />}>
//                         <FontAwesomeExample />
//                       </Suspense>
//                     )
//                   },
//                   {
//                     path: 'feather',
//                     element: (
//                       <Suspense fallback={<PhoenixLoader />}>
//                         <FeatherIconsExample />
//                       </Suspense>
//                     )
//                   },
//                   {
//                     path: 'unicons',
//                     element: (
//                       <Suspense fallback={<PhoenixLoader />}>
//                         <UniconsExample />
//                       </Suspense>
//                     )
//                   }
//                 ]
//               },
//               {
//                 path: 'components',
//                 children: [
//                   {
//                     path: 'accordion',
//                     element: <AccordionExample />
//                   },
//                   {
//                     path: 'avatar',
//                     element: <AvatarExample />
//                   },
//                   {
//                     path: 'alerts',
//                     element: <AlertsExample />
//                   },
//                   {
//                     path: 'button',
//                     element: <ButtonExample />
//                   },
//                   {
//                     path: 'badge',
//                     element: <BadgeExample />
//                   },
//                   {
//                     path: 'breadcrumb',
//                     element: <BreadcrumbExample />
//                   },
//                   {
//                     path: 'card',
//                     element: <CardExample />
//                   },
//                   {
//                     path: 'carousel/bootstrap',
//                     element: <BootstrapCarousel />
//                   },
//                   {
//                     path: 'carousel/swiper',
//                     element: <SwiperCarousel />
//                   },
//                   {
//                     path: 'collapse',
//                     element: <CollapseExample />
//                   },
//                   {
//                     path: 'dropdown',
//                     element: <DropdownExample />
//                   },
//                   {
//                     path: 'list-group',
//                     element: <ListGroupExample />
//                   },
//                   {
//                     path: 'countup',
//                     element: <CountupExample />
//                   },
//                   {
//                     path: 'draggable',
//                     element: <DraggableExample />
//                   },
//                   {
//                     path: 'modal',
//                     element: <ModalExample />
//                   },
//                   {
//                     path: 'offcanvas',
//                     element: <OffcanvasExample />
//                   },
//                   {
//                     path: 'progress-bar',
//                     element: <ProgressbarExample />
//                   },
//                   {
//                     path: 'placeholder',
//                     element: <PlaceholderExample />
//                   },
//                   {
//                     path: 'pagination',
//                     element: <PaginationExample />
//                   },
//                   {
//                     path: 'popovers',
//                     element: <PopoversExample />
//                   },
//                   {
//                     path: 'spinners',
//                     element: <SpinnerExample />
//                   },
//                   {
//                     path: 'toast',
//                     element: <ToastsExample />
//                   },
//                   {
//                     path: 'tooltips',
//                     element: <TooltipExample />
//                   },
//                   {
//                     path: 'calendar',
//                     element: <CalendarExample />
//                   },
//                   {
//                     path: 'navs-and-tabs/navs',
//                     element: <NavsExample />
//                   },
//                   {
//                     path: 'navs-and-tabs/tabs',
//                     element: <TabsExample />
//                   },
//                   {
//                     path: 'navs-and-tabs/navbar',
//                     element: <NavbarExample />
//                   },
//                   {
//                     path: 'pictures/lightbox',
//                     element: <LightboxExample />
//                   }
//                 ]
//               },
//               {
//                 path: 'forms',
//                 children: [
//                   {
//                     path: 'form-control',
//                     element: <FormControlExample />
//                   },
//                   {
//                     path: 'input-group',
//                     element: <InputGroupExample />
//                   },
//                   {
//                     path: 'select',
//                     element: <SelectExample />
//                   },
//                   {
//                     path: 'checks',
//                     element: <ChecksExample />
//                   },
//                   {
//                     path: 'range',
//                     element: <RangeExample />
//                   },
//                   {
//                     path: 'floating-labels',
//                     element: <FloatingLabelExample />
//                   },
//                   {
//                     path: 'layout',
//                     element: <FormLayoutExample />
//                   },
//                   {
//                     path: 'editor',
//                     element: <EditorExample />
//                   },
//                   {
//                     path: 'advance-select',
//                     element: <AdvanceSelectExample />
//                   },
//                   {
//                     path: 'date-picker',
//                     element: <DatePickerExample />
//                   },
//                   {
//                     path: 'emoji-button',
//                     element: <EmojiButtonExample />
//                   },
//                   {
//                     path: 'file-uploader',
//                     element: <FileUploaderExample />
//                   },
//                   {
//                     path: 'rating',
//                     element: <RatingExample />
//                   },
//                   {
//                     path: 'validation',
//                     element: <FormValidationExample />
//                   },
//                   {
//                     path: 'wizard',
//                     element: <WizardExample />
//                   }
//                 ]
//               },
//               {
//                 path: 'utilities',
//                 children: [
//                   {
//                     path: 'background',
//                     element: <BackgroundExample />
//                   },
//                   {
//                     path: 'borders',
//                     element: <BorderExample />
//                   },
//                   {
//                     path: 'colors',
//                     element: <ColorsExample />
//                   },
//                   {
//                     path: 'display',
//                     element: <DisplayExample />
//                   },
//                   {
//                     path: 'grid',
//                     element: <GridExample />
//                   },
//                   {
//                     path: 'flex',
//                     element: <FlexExample />
//                   },
//                   {
//                     path: 'stack',
//                     element: <StackExample />
//                   },
//                   {
//                     path: 'float',
//                     element: <FloatExample />
//                   },
//                   {
//                     path: 'interactions',
//                     element: <InteractionsExample />
//                   },
//                   {
//                     path: 'opacity',
//                     element: <OpacityExample />
//                   },
//                   {
//                     path: 'overflow',
//                     element: <OverflowExample />
//                   },
//                   {
//                     path: 'position',
//                     element: <PositionExample />
//                   },
//                   {
//                     path: 'shadows',
//                     element: <ShadowsExample />
//                   },
//                   {
//                     path: 'sizing',
//                     element: <SizingExample />
//                   },
//                   {
//                     path: 'spacing',
//                     element: <SpacingExample />
//                   },
//                   {
//                     path: 'typography',
//                     element: <TypographyExample />
//                   },
//                   {
//                     path: 'vertical-align',
//                     element: <VerticalAlignExample />
//                   },
//                   {
//                     path: 'visibility',
//                     element: <VisibilityExample />
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             path: '/documentation',
//             children: [
//               {
//                 path: 'getting-started',
//                 element: <GettingStarted />
//               },
//               {
//                 path: 'design-file',
//                 element: <DesignFile />
//               },
//               {
//                 path: 'customization',
//                 children: [
//                   {
//                     path: 'configuration',
//                     element: <Configuration />
//                   },
//                   {
//                     path: 'color',
//                     element: <Color />
//                   },
//                   {
//                     path: 'styling',
//                     element: <Styling />
//                   },
//                   {
//                     path: 'dark-mode',
//                     element: <DarkMode />
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             path: 'changelog',
//             element: <ChangeLog />
//           },
//           {
//             path: 'migrations',
//             element: <Migrations />
//           },
//           {
//             path: 'widgets',
//             element: <Widgets />
//           }
//         ]
//       },
//       {
//         element: <EcommerceLayout />,
//         path: '/apps/e-commerce/customer/',
//         children: [
//           {
//             path: 'homepage',
//             element: <Homepage />
//           },
//           {
//             path: 'product-details',
//             element: <ProductDetails />
//           },
//           {
//             path: 'cart',
//             element: <Cart />
//           },
//           {
//             path: 'checkout',
//             element: <Checkout />
//           },
//           {
//             path: 'shipping-info',
//             element: <ShippingInfo />
//           },
//           {
//             path: 'profile',
//             element: <Profile />
//           },
//           {
//             path: 'products-filter',
//             element: <ProductsFilter />
//           },
//           {
//             path: 'wishlist',
//             element: <Wishlist />
//           },
//           {
//             path: 'favorite-stores',
//             element: <FavoriteStores />
//           },
//           {
//             path: 'order-tracking',
//             element: <OrderTracking />
//           },
//           {
//             path: 'invoice',
//             element: <Invoice />
//           }
//         ]
//       },
//       {
//         path: 'pages/landing',
//         children: [
//           {
//             path: 'default',
//             element: <Default />
//           },
//           {
//             path: 'alternate',
//             element: <Alternate />
//           }
//         ]
//       },
//       {
//         path: '/pages/errors/',
//         children: [
//           {
//             path: '404',
//             element: <Error404 />
//           },
//           {
//             path: '403',
//             element: <Error403 />
//           },
//           {
//             path: '500',
//             element: <Error500 />
//           }
//         ]
//       },
//       /**
//        * Auth Page routes - SP - 20-04-2024
//        */
//       {
//         path: '/auth/',
//         children: [
//           {
//             path: 'sign-up',
//             element: <SingUp />
//           },
//           {
//             path: 'sign-in',
//             element: <SingIn />
//           }
//         ]
//       },
//       {
//         path: '/pages/authentication/simple/',
//         children: [
//           {
//             path: 'sign-in',
//             element: <SimpleSignIn />
//           },
//           {
//             path: 'sign-up',
//             element: <SimpleSignUp />
//           },
//           {
//             path: 'sign-out',
//             element: <SimpleSignOut />
//           },
//           {
//             path: 'forgot-password',
//             element: <SimpleForgotPassword />
//           },
//           {
//             path: 'reset-password',
//             element: <SimpleResetPassword />
//           },
//           {
//             path: 'lock-screen',
//             element: <SimpleLockScreen />
//           },
//           {
//             path: '2FA',
//             element: <SimpleTwoFA />
//           }
//         ]
//       },
//       {
//         path: '/pages/authentication/split/',
//         children: [
//           {
//             path: 'sign-in',
//             element: <SplitSignIn />
//           },
//           {
//             path: 'sign-up',
//             element: <SplitSignUp />
//           },
//           {
//             path: 'sign-out',
//             element: <SplitSignOut />
//           },
//           {
//             path: 'forgot-password',
//             element: <SplitForgotPassword />
//           },
//           {
//             path: 'reset-password',
//             element: <SplitResetPassword />
//           },
//           {
//             path: 'lock-screen',
//             element: <SplitLockScreen />
//           },
//           {
//             path: '2FA',
//             element: <SplitTwoFA />
//           }
//         ]
//       },
//       {
//         path: '/pages/authentication/card/',
//         children: [
//           {
//             path: 'sign-in',
//             element: <CardSignIn />
//           },
//           {
//             path: 'sign-up',
//             element: <CardSignUp />
//           },
//           {
//             path: 'sign-out',
//             element: <CardSignOut />
//           },
//           {
//             path: 'forgot-password',
//             element: <CardForgotPassword />
//           },
//           {
//             path: 'reset-password',
//             element: <CardResetPassword />
//           },
//           {
//             path: 'lock-screen',
//             element: <CardLockScreen />
//           },
//           {
//             path: '2FA',
//             element: <CardTwoFA />
//           }
//         ]
//       },
//       {
//         path: 'showcase',
//         element: <Showcase />
//       },
//       {
//         path: '*',
//         element: <Error404 />
//       }
//     ]
//   }
// ];

const {
  Dashboard_One,
  Dashboard_Two,
  Auth,
  SignIn: SignInRoute,
  SignUp: SingUpRoute,
  TwoFA,
  ForgotPassword,
  ResetPassword,
  LockPage,
  LogoutPage,
  FilteredUserEvents,
  UserEventsProfile,
  MembersRoute,
  AddMembersRoute,
  Profile,
  UpcommingFeature,
  SignOutPage,
  EventDetailsPage,
  PendingUserQue,
  TwoFASignIn,
  ChangePassword,
  Search,
  ZoomCall
} = Routes;

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: Dashboard_One,
        element: (
          <MainLayoutProvider>
            <Protected>
              <MainLayout />
            </Protected>
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Ecommerce />
          },
          {
            path: Dashboard_Two,
            element: <Ecommerce />
          },
          {
            path: FilteredUserEvents,
            element: <FilteredEvents />
          },
          {
            path: `${FilteredUserEvents}/:slug`,
            element: <FilteredEvents />
          },
          {
            path: `${UserEventsProfile}/:slug`,
            element: <UserEventsProfileComponent />
          },
          {
            path: `${EventDetailsPage}/:event_id`,
            element: <EventDetailsComponent />
          },
          // {
          //   path: PendingUserQue,
          //   element: <PendingUserQueComponent />
          // },
          {
            path: Profile,
            element: <ProfilePage />
          },
          {
            path: Search,
            element: <SearchPage />
          }
        ]
      },
      {
        path: ZoomCall,
        element: <ZoomCallComponent />
      },
      // {
      //   element: <EcommerceLayout />,
      //   path: '/apps/e-commerce/customer/',
      //   children: [
      //     {
      //       path: 'homepage',
      //       element: <Homepage />
      //     },
      //     {
      //       path: 'product-details',
      //       element: <ProductDetails />
      //     },
      //     {
      //       path: 'cart',
      //       element: <Cart />
      //     },
      //     {
      //       path: 'checkout',
      //       element: <Checkout />
      //     },
      //     {
      //       path: 'shipping-info',
      //       element: <ShippingInfo />
      //     },
      //     {
      //       path: 'profile',
      //       element: <Profile />
      //     },
      //     {
      //       path: 'products-filter',
      //       element: <ProductsFilter />
      //     },
      //     {
      //       path: 'wishlist',
      //       element: <Wishlist />
      //     },
      //     {
      //       path: 'favorite-stores',
      //       element: <FavoriteStores />
      //     },
      //     {
      //       path: 'order-tracking',
      //       element: <OrderTracking />
      //     },
      //     {
      //       path: 'invoice',
      //       element: <Invoice />
      //     }
      //   ]
      // },
      // {
      //   path: 'pages/landing',
      //   children: [
      //     {
      //       path: 'default',
      //       element: <Default />
      //     },
      //     {
      //       path: 'alternate',
      //       element: <Alternate />
      //     }
      //   ]
      // },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: MembersRoute,
        element: (
          <MainLayoutProvider>
            <Protected>
              <Restricted>
                <MainLayout />
              </Restricted>
            </Protected>
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Members />
          },
          {
            path: AddMembersRoute,
            element: <AddMember />
          }
        ]
      },
      {
        path: PendingUserQue,
        element: (
          <MainLayoutProvider>
            <Protected>
              <RestrictedDsRoutes>
                <MainLayout />
              </RestrictedDsRoutes>
            </Protected>
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <PendingUserQueComponent />
          }
        ]
      },
      /**
       * Auth Page routes - SP - 20-04-2024
       */
      {
        path: Auth,
        children: [
          {
            path: ChangePassword,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <ChangePasswordComponent />
              </Suspense>
            )
          }
        ]
      },
      {
        path: Auth,
        loader: async () => await isAuthenticated(),
        children: [
          {
            path: SingUpRoute,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <SingUp />
              </Suspense>
            )
          },
          {
            path: SignInRoute,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <SingIn />
              </Suspense>
            )
          },
          {
            path: TwoFA,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <TwoFa />
              </Suspense>
            )
          },
          {
            path: TwoFASignIn,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <TwoFaLogin />
              </Suspense>
            )
          },
          {
            path: ForgotPassword,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <ForgotPasswordComponent />
              </Suspense>
            )
          },
          {
            path: ResetPassword,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <ResetPasswordComponent />
              </Suspense>
            )
          },
          {
            path: LogoutPage,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <LogoutPageComponent />
              </Suspense>
            )
          }
        ]
      },
      {
        path: SignOutPage,
        element: (
          <MainLayoutProvider>
            <Protected>
              <MainLayout />
            </Protected>
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <SignOut />
              </Suspense>
            )
          }
        ]
      },
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      },
      {
        path: UpcommingFeature,
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <NewFeature />
          }
        ]
      },
      {
        path: Auth,
        children: [
          {
            path: LockPage,
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <LockPageComponent />
              </Suspense>
            )
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
