/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/base/Button';
import SocialProfileCard from '../components/SocialProfileCard';
import SettingsChangePassword from '../components/SettingsChangePassword';
import SettingsCompanyInfo from '../components/SettingsCompanyInfo';
// import SettingsPersonalInfo from '../components/SettingsPersonalInfo';
import SocialSettingsSideBar from '../components/SocialSettingsSideBar';
import { Col, Row, Form as BForm, Modal } from 'react-bootstrap';
import profileImage from 'assets/img/icons/avatar.svg';
import useProfileHook from './useProfileHook';
import { ErrorMessage, Form, Formik } from 'formik';
import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const Profile = () => {
  const {
    initialAddProfileValues,
    validationAddProfileSchema,
    initialPasswordValues,
    validationPasswordSchema,
    handleSubmit,
    handleChangePasswordSubmit,
    memberProfile,
    IsLoadingMemberProfile,
    btnDisable,
    handleRequestToAdmin,
    handleDeleteAccount,
    personalInfoFormRef
  } = useProfileHook();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  if (IsLoadingMemberProfile) {
    return <>Loading...</>;
  }

  return (
    <div className="mb-9">
      <Row className="g-6">
        <Col xs={12} xl={4}>
          <SocialProfileCard
            avatar={memberProfile?.profile_image ?? profileImage}
            className="mb-5"
            profileData={memberProfile}
          />
          <SocialSettingsSideBar memberProfile={memberProfile} />
        </Col>
        <Col xs={12} xl={8}>
          <div className="border-bottom mb-4">
            <Formik
              innerRef={personalInfoFormRef}
              initialValues={initialAddProfileValues}
              validationSchema={validationAddProfileSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, handleBlur, values }) => {
                return (
                  <Form>
                    <div className="mb-3">
                      <h4 className="mb-4">Personal Information</h4>
                      <Row className="g-3">
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="firstName"
                                name="first_name"
                                type="text"
                                placeholder="First Name"
                                className="form-icon-input"
                                value={values?.first_name ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="firstName"
                                className="form-icon-label text-body-tertiary"
                              >
                                FIRST NAME
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="fs-9 fw-normal text-danger"
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="lastName"
                                name="last_name"
                                type="text"
                                placeholder="Last Name"
                                className="form-icon-input"
                                value={values?.last_name ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="lastName"
                                className="form-icon-label text-body-tertiary"
                              >
                                LAST NAME
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="fs-9 fw-normal text-danger"
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Enter your email"
                                className="form-icon-input"
                                value={values?.email ?? ''}
                                disabled={true}
                              />
                              <label
                                htmlFor="email"
                                className="form-icon-label text-body-tertiary"
                              >
                                ENTER YOUR EMAIL
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="phone"
                                name="phone_no"
                                type="tel"
                                placeholder="Enter your phone"
                                className="form-icon-input"
                                value={values?.phone_no ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="phone"
                                className="form-icon-label text-body-tertiary"
                              >
                                ENTER YOUR PHONE
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                          <ErrorMessage
                            name="phone_no"
                            component="div"
                            className="fs-9 fw-normal text-danger"
                          />
                        </Col>
                        <Col xs={12} sm={8}></Col>
                        <Col xs={12} sm={4} style={{ textAlign: 'end' }}>
                          <Button
                            type="button"
                            variant="link"
                            className="p-0"
                            style={{ display: 'contents' }}
                            onClick={() =>
                              personalInfoFormRef.current?.submitForm()
                            }
                            disabled={btnDisable}
                          >
                            Confirm
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <Row className="gx-3 mb-6 gy-6 gy-sm-3">
              <Col xs={12} sm={6}>
                <SettingsCompanyInfo />
              </Col>
              <Col xs={12} sm={6}>
                <div className="d-flex justify-content-between">
                  <h4 className="mb-4">Change Password</h4>
                  {/* <Button
                    type="button"
                    variant="link"
                    className="p-0"
                    style={{ display: 'contents' }}
                    onClick={() => personalInfoFormRef.current?.submitForm()}
                    disabled={btnDisable}
                  >
                    Confirm
                  </Button> */}
                </div>
                <Formik
                  initialValues={initialPasswordValues}
                  validationSchema={validationPasswordSchema}
                  onSubmit={handleChangePasswordSubmit}
                >
                  {({ handleChange, handleBlur, resetForm }) => (
                    <Form>
                      <SettingsChangePassword
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                      />

                      <div className="text-end mt-6">
                        <Button
                          type="reset"
                          variant="phoenix-secondary"
                          className="me-2"
                          onClick={() => resetForm()}
                        >
                          Cancel Changes
                        </Button>
                        <Button
                          type="submit"
                          variant="phoenix-primary"
                          disabled={btnDisable}
                        >
                          Save Information
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
          <Row className="gy-5">
            <Col xs={12} md={6}>
              <h4 className="text-body-emphasis">Request Admin Access</h4>
              <p className="text-body-tertiary">
                Request access to become an account admin
              </p>
              <Button
                variant="phoenix-warning"
                disabled={btnDisable}
                onClick={handleRequestToAdmin}
              >
                Request
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-body-emphasis">Account Deletion</h4>
              <p className="text-body-tertiary">
                Delete your account (you can always make a new one)
              </p>
              <Button
                variant="phoenix-danger"
                disabled={btnDisable}
                onClick={handleShow}
              >
                Delete account
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are you sure want to delete account?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDeleteAccount();
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profile;

// import React, { useRef, useState } from 'react';

// const Profile = () => {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   const videoRef = useRef(null) as any;
//   const [isPipActive, setIsPipActive] = useState(false);

//   const handleEnterPip = async () => {
//     try {
//       if (videoRef.current) {
//         // Enter Picture-in-Picture mode
//         await videoRef.current.requestPictureInPicture();
//         setIsPipActive(true);
//       }
//     } catch (error) {
//       console.error('Error entering Picture-in-Picture', error);
//     }
//   };

//   const handleExitPip = async () => {
//     try {
//       if (document.pictureInPictureElement) {
//         // Exit Picture-in-Picture mode
//         await document.exitPictureInPicture();
//         setIsPipActive(false);
//       }
//     } catch (error) {
//       console.error('Error exiting Picture-in-Picture', error);
//     }
//   };

//   // Automatically handle Picture-in-Picture state
//   React.useEffect(() => {
//     const handlePipChange = () => {
//       setIsPipActive(Boolean(document.pictureInPictureElement));
//     };

//     document.addEventListener('enterpictureinpicture', handlePipChange);
//     document.addEventListener('leavepictureinpicture', handlePipChange);

//     return () => {
//       document.removeEventListener('enterpictureinpicture', handlePipChange);
//       document.removeEventListener('leavepictureinpicture', handlePipChange);
//     };
//   }, []);

//   return (
//     <div>
//       <video
//         ref={videoRef}
//         width="600"
//         controls
//         src="https://www.w3schools.com/html/mov_bbb.mp4"
//       ></video>
//       <div style={{ marginTop: '10px' }}>
//         {!isPipActive ? (
//           <button onClick={handleEnterPip}>Enter PiP</button>
//         ) : (
//           <button onClick={handleExitPip}>Exit PiP</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Profile;

// import React, { useRef, useState } from 'react';
// import html2canvas from 'html2canvas';

// const Profile: React.FC = () => {
//   const divRef = useRef<HTMLDivElement | null>(null);
//   const videoRef = useRef<HTMLVideoElement | null>(null);
//   const [isPipActive, setIsPipActive] = useState(false);

//   const captureDivToCanvas = async () => {
//     if (divRef.current) {
//       // Capture the div as a canvas using html2canvas
//       const canvas = await html2canvas(divRef.current);
//       const stream = canvas.captureStream(); // Capture the canvas stream

//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         await videoRef.current.play(); // Play the video to activate stream
//       }
//     }
//   };

//   const handleEnterPip = async () => {
//     try {
//       await captureDivToCanvas(); // Capture the div content to the canvas

//       if (videoRef.current) {
//         await videoRef.current.requestPictureInPicture(); // Enter PiP mode
//         setIsPipActive(true);
//       }
//     } catch (error) {
//       console.error('Error entering Picture-in-Picture', error);
//     }
//   };

//   const handleExitPip = async () => {
//     try {
//       if (document.pictureInPictureElement) {
//         await document.exitPictureInPicture(); // Exit PiP mode
//         setIsPipActive(false);
//       }
//     } catch (error) {
//       console.error('Error exiting Picture-in-Picture', error);
//     }
//   };

//   return (
//     <div>
//       <div
//         ref={divRef}
//         style={{
//           width: '400px',
//           height: '300px',
//           backgroundColor: 'lightblue',
//           padding: '20px'
//         }}
//       >
//         <h2>This is a sample div</h2>
//         <p>Content inside a div to be shown in PiP mode via a video element.</p>
//       </div>

//       {/* Hidden video element to hold the div content for PiP */}
//       <video ref={videoRef} style={{ display: 'none' }} />

//       <div style={{ marginTop: '10px' }}>
//         {!isPipActive ? (
//           <button onClick={handleEnterPip}>Enter PiP</button>
//         ) : (
//           <button onClick={handleExitPip}>Exit PiP</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Profile;

// import React, { useEffect, useRef, useState } from 'react';
// import ZoomMtg from '@zoomus/websdk';
// import html2canvas from 'html2canvas';
// import '@zoomus/websdk/dist/css/bootstrap.css';
// import '@zoomus/websdk/dist/css/react-select.css';

// const Profile: React.FC = () => {
//   const videoRef = useRef<HTMLVideoElement | null>(null);
//   const zoomDivRef = useRef<HTMLDivElement | null>(null);
//   const [isPipActive, setIsPipActive] = useState(false);

//   useEffect(() => {
//     const zoomInit = () => {
//       // Zoom Web SDK setup
//       ZoomMtg.preLoadWasm(); // Preload required SDK assets
//       ZoomMtg.prepareJssdk(); // Prepare SDK

//       const meetConfig = {
//         apiKey: 'YOUR_API_KEY', // Replace with your API Key
//         signature: 'YOUR_SIGNATURE', // Generated on the server-side
//         meetingNumber: 'YOUR_MEETING_NUMBER',
//         userName: 'YOUR_USERNAME',
//         passWord: 'YOUR_MEETING_PASSWORD',
//         leaveUrl: 'http://yourapp.com', // Where to redirect after leaving the meeting
//         role: 0 // 0 = participant, 1 = host
//       };

//       // Initialize the Zoom meeting
//       ZoomMtg.init({
//         leaveUrl: meetConfig.leaveUrl,
//         isSupportAV: true,
//         success: initResponse => {
//           console.log('Zoom SDK Initialized successfully', initResponse);

//           // Join the meeting after initialization
//           ZoomMtg.join({
//             signature: meetConfig.signature,
//             meetingNumber: meetConfig.meetingNumber,
//             userName: meetConfig.userName,
//             apiKey: meetConfig.apiKey,
//             passWord: meetConfig.passWord,
//             success: joinResponse => {
//               console.log('Successfully joined Zoom meeting', joinResponse);
//             },
//             error: error => {
//               console.error('Error joining Zoom meeting', error);
//             }
//           });
//         },
//         error: error => {
//           console.error('Error initializing Zoom SDK', error);
//         }
//       });
//     };

//     zoomInit();
//   }, []);

//  const captureZoomDivToCanvas = async () => {
//    if (zoomDivRef.current) {
//      const canvas = await html2canvas(zoomDivRef.current);
//      const stream = canvas.captureStream();
//      if (videoRef.current) {
//        videoRef.current.srcObject = stream;
//        await videoRef.current.play();
//      }
//    }
//  };

//  const handleEnterPip = async () => {
//    try {
//      await captureZoomDivToCanvas();
//      if (videoRef.current) {
//        await videoRef.current.requestPictureInPicture();
//        setIsPipActive(true);
//      }
//    } catch (error) {
//      console.error('Error entering PiP', error);
//    }
//  };

//   const handleExitPip = async () => {
//     try {
//       if (document.pictureInPictureElement) {
//         await document.exitPictureInPicture();
//         setIsPipActive(false);
//       }
//     } catch (error) {
//       console.error('Error exiting PiP', error);
//     }
//   };

//   return (
//     <div>
//       {/* This div will contain the Zoom meeting */}
//       <div ref={zoomDivRef} id="zmmtg-root"></div>

//       {/* Hidden video element to show the Zoom content in PiP */}
//       <video ref={videoRef} style={{ display: 'none' }} />

//       <div>
//         {!isPipActive ? (
//           <button onClick={handleEnterPip}>Enter PiP</button>
//         ) : (
//           <button onClick={handleExitPip}>Exit PiP</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Profile;
