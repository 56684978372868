/* eslint-disable @typescript-eslint/no-explicit-any */
import { get_api } from 'apis';
import { ApiRoutes } from 'common/api-routes';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  CallsRootProps,
  CurrentPreviousMonth,
  totalCallTimeProps,
  totalUsageProps,
  activeUserProps,
  allActiveUserProps,
  dashboardEventList,
  EventListProps
} from './dashboard.interface';
import { format, parseISO } from 'date-fns';
import { getSixDaysBeforeDate } from 'helpers/date-flter';
import { Limit, PageIndex, PageSize } from 'common/variables';
import { useAppDispatch } from '../../../redux/hooks';
import { toast } from 'react-toastify';

const useDashboardHook = (datehere: any = null) => {
  const [filter, setfilter] = useState({
    keyValue: '1',
    isActive: true
  });
  const [pagination, setPagination] = useState({
    pageIndex: PageIndex,
    pageSize: PageSize
  });
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const {
    common,
    userCount,
    totalCalls,
    callTime: callTimeUrl,
    totalUsage,
    activeUsers,
    callProportion: callProportionUrl,
    events
  } = ApiRoutes;

  const dispatch = useAppDispatch();

  const fetchUsers = async () => {
    const url = `${common}${userCount}`;
    return await get_api(url, dispatch);
  };
  const navigate = useNavigate();

  const {
    data: ourUsers,
    isLoading: isLoadingOurUsers,
    isError: isErrorOurUsers,
    error: errorOurUsers
  } = useQuery('dashboard', async () => await fetchUsers(), {
    refetchOnWindowFocus: false, // Disable refetching on window focus
    retry: false // Disable retries on failure
  });

  // function getMonthList() {
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();
  //   const currentMonth = currentDate.getMonth();

  //   const startMonth = new Date(currentYear - 1, currentMonth, 1); // Start from last year, same month
  //   const endMonth = new Date(currentYear + 1, currentMonth + 1, 0); // End next year, same month

  //   const monthNames = [
  //     'Jan',
  //     'Feb',
  //     'Mar',
  //     'Apr',
  //     'May',
  //     'Jun',
  //     'Jul',
  //     'Aug',
  //     'Sep',
  //     'Oct',
  //     'Nov',
  //     'Dec'
  //   ];
  //   const monthList = [];

  //   const current = startMonth;
  //   while (current <= endMonth) {
  //     const month = monthNames[current.getMonth()];
  //     const year = current.getFullYear();

  //     monthList.push(`${month}, ${year}`);
  //     current.setMonth(current.getMonth() + 1); // Move to the next month
  //   }

  //   return monthList;
  // }

  function getMonthList() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Start from the current month of the previous year
    const startMonth = new Date(currentYear - 1, currentMonth, 1);
    // End at the current month of the current year
    const endMonth = new Date(currentYear, currentMonth + 1, 0);

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const monthList = [];

    const current = startMonth;
    while (current <= endMonth) {
      const month = monthNames[current.getMonth()];
      const year = current.getFullYear();

      monthList.push(`${month}, ${year}`);
      current.setMonth(current.getMonth() + 1); // Move to the next month
    }

    return monthList;
  }

  const fetchTotalCalls = async (
    dateDate: any
  ): Promise<CallsRootProps | undefined> => {
    const url = `${common}${totalCalls}`;
    const currentMonthData = (await get_api(
      `${url}?start_date=${dateDate.currentStartDate ?? ''}&end_date=${
        dateDate.currentEndDate ?? ''
      }`,
      dispatch
    )) as CurrentPreviousMonth;
    const previousMonthData = (await get_api(
      `${url}?start_date=${dateDate.previousStartDate ?? ''}&end_date=${
        dateDate.previousEndDate ?? ''
      }`,
      dispatch
    )) as CurrentPreviousMonth;
    return {
      currentMonth: currentMonthData,
      previousMonth: previousMonthData
    };
  };

  const {
    data: calls,
    isLoading: isLoadingCalls,
    isError: isErrorCalls,
    error: errorCalls
  } = useQuery(
    ['dashboard-total-calls', datehere],
    async () => await fetchTotalCalls(datehere),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const todaysDate = new Date();
  const currentDate = `${todaysDate.getFullYear()}-${String(
    todaysDate.getMonth() + 1
  ).padStart(2, '0')}-${String(todaysDate.getDate()).padStart(2, '0')}`;
  const sixDayBeforeDate = getSixDaysBeforeDate(currentDate);
  const previousWeekDate = getSixDaysBeforeDate(sixDayBeforeDate);

  const fetchTotalCallTime = async (
    value1: string,
    value2: string
  ): Promise<totalCallTimeProps> => {
    const url = `${common}${callTimeUrl}`;
    return (await get_api(
      `${url}?start_date=${value2}&end_date=${value1}`,
      dispatch
    )) as totalCallTimeProps;
  };

  const {
    data: callTime,
    isLoading: isLoadingcallTime,
    isError: isErrorcallTime,
    error: errorcallTime
  } = useQuery(
    ['dashboard-total-call-time', currentDate, sixDayBeforeDate],
    async () => await fetchTotalCallTime(currentDate, sixDayBeforeDate),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchTotalUsage = async (
    value1: string,
    value2: string
  ): Promise<totalUsageProps> => {
    const url = `${common}${totalUsage}`;
    return (await get_api(
      `${url}?start_date=${value2}&end_date=${value1}`,
      dispatch
    )) as totalUsageProps;
  };

  const {
    data: usage,
    isLoading: isLoadingusage,
    isError: isErrorusage,
    error: errorusage
  } = useQuery(
    ['dashboard-total-usage', currentDate, sixDayBeforeDate],
    async () => await fetchTotalUsage(currentDate, sixDayBeforeDate),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchActiveUsers = async (
    value1: string,
    value2: string,
    value3: string
  ): Promise<allActiveUserProps | undefined> => {
    const url = `${common}${activeUsers}`;
    try {
      const currentWeek = (await get_api(
        `${url}?start_date=${value2}&end_date=${value1}`,
        dispatch
      )) as activeUserProps;

      const lastWeek = (await get_api(
        `${url}?start_date=${value3}&end_date=${value2}`,
        dispatch
      )) as activeUserProps;

      return {
        currentWeek,
        lastWeek
      };
    } catch (error: any) {
      toast.error(error?.data?.detail);
      return undefined;
    }
  };

  const {
    data: activeUser,
    isLoading: isLoadingactiveUser,
    isError: isErroractiveUser,
    error: erroractiveUser
  } = useQuery(
    ['dashboard-active-user', currentDate, sixDayBeforeDate, previousWeekDate],
    async () =>
      await fetchActiveUsers(currentDate, sixDayBeforeDate, previousWeekDate),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchCallProportion = async (value1: string, value2: string) => {
    const url = `${common}${callProportionUrl}`;
    return await get_api(
      `${url}?start_date=${value2}&end_date=${value1}`,
      dispatch
    );
  };

  const {
    data: callProportion,
    isLoading: isLoadingcallProportion,
    isError: isErrorcallProportion,
    error: errorcallProportion
  } = useQuery(
    ['dashboard-call-proportion', currentDate, sixDayBeforeDate],
    async () => await fetchCallProportion(currentDate, sixDayBeforeDate),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const dashboardEventListing: dashboardEventList[] = [];

  const fetchEvents = async (
    value1: number,
    value2: number
  ): Promise<EventListProps> => {
    const url = `${common}${events}`;
    value1 = value1 + 1;
    const data = (await get_api(
      `${url}?page=${value1}&page_size=${value2}`,
      dispatch
    )) as EventListProps;
    data.pages = Math.ceil(data.count / Limit);
    return data;
  };

  const {
    data: eventList,
    isLoading: isLoadingeventList,
    isError: isErroreventList,
    error: erroreventList
  } = useQuery(
    ['event-list', pagination],
    async () => await fetchEvents(pagination.pageIndex, pagination.pageSize),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const formatTimestamp = (timestamp: string): string => {
    const date = parseISO(timestamp);
    const now = new Date();

    const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    if (differenceInMinutes < 1) {
      return 'just now';
    } else if (differenceInMinutes < 60) {
      return `${Math.floor(differenceInMinutes)} minutes ago`;
    } else if (differenceInMinutes < 1440) {
      return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    } else {
      return format(date, 'MMM dd, h:mm a');
    }
  };

  return {
    search,
    setSearch,
    sortBy,
    setSortBy,
    sortType,
    setSortType,
    ourUsers,
    isLoadingOurUsers,
    isErrorOurUsers,
    errorOurUsers,
    filter,
    setfilter,
    navigate,
    getMonthList,
    calls,
    isLoadingCalls,
    isErrorCalls,
    errorCalls,
    callTime,
    isLoadingcallTime,
    isErrorcallTime,
    errorcallTime,
    usage,
    isLoadingusage,
    isErrorusage,
    errorusage,
    activeUser,
    isLoadingactiveUser,
    isErroractiveUser,
    erroractiveUser,
    getSixDaysBeforeDate,
    callProportion,
    isLoadingcallProportion,
    isErrorcallProportion,
    errorcallProportion,
    dashboardEventListing,
    eventList,
    isLoadingeventList,
    isErroreventList,
    erroreventList,
    formatTimestamp,
    PageSize,
    pagination,
    setPagination
  };
};

export default useDashboardHook;
