/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PendingUserQueResult } from './pendintUqeInterface';
import usePendingUserQue from './usePendingUserQue';
import { useEffect, useState } from 'react';
import { AuthState } from '../../../redux/features/authSlice';
import { dsCallEnd } from '../../../redux/features/dsJoinCallSlice';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import uitoolkit from '@zoom/videosdk-ui-toolkit';
import { ApiRoutes } from 'common/api-routes';
import { put_api } from 'apis';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';

const ZoomCall = () => {
  const [params, setParams] = useState<any>(null);
  const auth = useAppSelector(state => state.auth) as AuthState;
  const { callQueueList, setCallQueueList, setHide, setsocketEventType } =
    usePendingUserQue();
  const { common, receivedCall, joined, hold } = ApiRoutes;
  const [callId, setCallId] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      // Prevent the immediate closure of the tab
      event.preventDefault();
      event.returnValue = ''; // This prompts the user with a confirmation dialog

      // Call the holdCall API
      // await holdCall(callId);
      await sessionClosed();
      localStorage.removeItem('zoomCallKeys');
      localStorage.removeItem('call_active_event_id'); // Remove specific data from localStorage
      localStorage.removeItem('dispatcher_join_call_id');

      // Wait for 3 seconds before allowing the tab to close
      setTimeout(() => {
        // Remove the event listener after the delay to avoid interference
        sessionClosed();
        window.removeEventListener('beforeunload', handleBeforeUnload);
        // Attempt to close the tab after the 3-second delay
        window.close();
      }, 3000);
    };

    // Add the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      sessionClosed();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const storedParams: any = localStorage.getItem('zoomCallKeys');
    if (storedParams) {
      setParams(JSON.parse(storedParams));
      getVideoSDKJWT(
        storedParams?.token ?? '',
        storedParams?.sessionName ?? '',
        storedParams?.callIdnumber ?? ''
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (params)
        await getVideoSDKJWT(
          params?.token ?? '',
          params?.sessionName ?? '',
          params?.callIdnumber ?? ''
        );
    })(); // Immediately invoked function
  }, [params]);

  useEffect(() => {
    // Function to find the .controls element and add the custom button
    const findControlsElement = () => {
      const container = document.querySelector(
        '#sessionContainer .controlskit .controls'
      );

      if (container) {
        // Check if the custom button already exists
        const customButtonExists =
          container.querySelector('.custom-action-btn');

        if (!customButtonExists) {
          // Add a custom button if it doesn't exist
          const customButton = document.createElement('button');
          customButton.innerText = 'Hold';
          customButton.className = 'custom-action-btn';
          customButton.style.backgroundColor = '#0d72ed'; // Green background
          customButton.style.color = 'white'; // White text
          customButton.style.border = 'none'; // No border
          customButton.style.padding = '10px 20px'; // Padding
          customButton.style.textAlign = 'center'; // Centered text
          customButton.style.textDecoration = 'none'; // No underline
          customButton.style.display = 'inline-block'; // Inline-block
          customButton.style.fontSize = '20px'; // Font size
          customButton.style.margin = '4px 2px'; // Margin
          customButton.style.cursor = 'pointer'; // Pointer cursor
          customButton.style.borderRadius = '5px'; // Rounded corners
          customButton.onclick = () => {
            holdCall(callId);
          };

          container.appendChild(customButton);
        } else {
          return true; // Indicates that the custom button exists, and we should stop the interval
        }
      }
      return false; // Continue polling if the controls element or custom button is not found
    };

    // Set an interval to check for the controls element every 500ms
    const intervalId = setInterval(() => {
      const shouldStop = findControlsElement();
      if (shouldStop) {
        clearInterval(intervalId); // Stop the interval when the button is found
      }
    }, 500);

    // Cleanup the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [callId]);

  let sessionContainer: any;

  const holdCall = async (callIdHere: string) => {
    const url = `${common}${receivedCall}/${callIdHere}/${hold}`;
    const res = (await put_api(url, null)) as any;
    const filteredResults = callQueueList.results.map(
      (result: PendingUserQueResult) => {
        if (result.call_id === res.call_id) {
          return {
            ...result,
            session_status: res.session_status
          };
        }
        return result;
      }
    );

    // Delay the execution of the session close and cleanup
    setTimeout(() => {
      setsocketEventType('call_on_hold');
      const zoomContainer = document.getElementById('sessionContainer');
      uitoolkit.closeSession(zoomContainer);
      setHide(true);
      setCallQueueList({ ...callQueueList, results: filteredResults });
      localStorage.removeItem('zoomCallKeys');
      localStorage.removeItem('call_active_event_id'); // Remove specific data from localStorage
      localStorage.removeItem('dispatcher_join_call_id');
      window.close(); // Close the current tab
    }, 1500); // 5000ms = 5 seconds
  };

  async function getVideoSDKJWT(
    jwtToken: string,
    session: string,
    call_id: string
  ) {
    try {
      const url = `${common}${receivedCall}/${call_id}/${joined}`;
      await put_api(url, null);
      setCallId(call_id);
      sessionContainer = document.getElementById('sessionContainer');
      setHide(false);
      localStorage.setItem('dispatcher_join_call_id', call_id);
      joinSession(jwtToken, session);
    } catch (error) {
      // localStorage.removeItem('zoomCallKeys'); // Remove specific data from localStorage
      // localStorage.removeItem('dispatcher_join_call_id');
      // window.close(); // Close the current tab
    }
  }

  function joinSession(jwtToken: string, session: string) {
    const config = {
      videoSDKJWT: jwtToken,
      sessionName: session,
      userName: `Dispatcher-${auth.user?.email}`,
      sessionPasscode: '',
      features: ['video', 'audio'],
      options: { init: {}, audio: {}, video: {} }
    };
    uitoolkit.joinSession(sessionContainer, config);
    uitoolkit.onSessionClosed(sessionClosed);
  }

  const sessionClosed = async () => {
    // Delay the execution of the session close and cleanup
    const endCallId: any = localStorage.getItem('dispatcher_join_call_id');
    localStorage.setItem('end_call', endCallId);
    dispatch(dsCallEnd(endCallId));
    setTimeout(() => {
      setsocketEventType('call_ended');
      uitoolkit.closeSession(sessionContainer);
      localStorage.removeItem('dispatcher_join_call_id');
      localStorage.removeItem('call_active_event_id');
      localStorage.removeItem('zoomCallKeys'); // Remove specific data from localStorage
      window.close(); // Close the current tab
      setHide(true);
    }, 2000); // 5000ms = 5 seconds
  };

  return (
    <section
      style={{
        display: 'flex',
        paddingTop: 0,
        justifyContent: 'center'
      }}
    >
      <div id="sessionContainer" style={{ width: '900px' }}></div>
    </section>
  );
};

export default ZoomCall;
