/**
 * Cover scenarios
 * New call and existing call not null then banner dispaly
 * New call bind into list automatically
 * Once call join by DS, Then status changed to Joined
 * Once call join by DS, Then that call button set disabled
 * Joined call record delete for other DS user
 * If DS user already in one call, If he/she try to connect another call. Then show message.
 */

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AdvanceTable from 'components/base/AdvanceTable';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { PendingUserQueResult } from './pendintUqeInterface';
import usePendingUserQue from './usePendingUserQue';
import { Routes } from 'common/router-name';
import { Row, Col, Modal } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { useEffect, useRef, useState } from 'react';
import { AuthState } from '../../../redux/features/authSlice';
import {
  dsCallListUpdate,
  dsCallRemoveFromListUpdate,
  dsCallGetEvent,
  dsCallDispatcherJoinUpdateWaitTime,
  dsCallDispatcherJoinRemoveData
} from '../../../redux/features/dsCallSlice';
import { dsCallEnd } from '../../../redux/features/dsJoinCallSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { format, parseISO } from 'date-fns';
import { initialSession } from '../../../redux/features/sessionClose';

const EcomLatestReviewsTable = () => {
  const { EventDetailsPage, ZoomCall } = Routes;
  const dispatcher = useAppDispatch();
  const auth = useAppSelector(state => state.auth) as AuthState;
  const [endedCallId, setEndedCallId] = useState(() => {
    return localStorage.getItem('end_call');
  });
  const {
    pagination,
    setPagination,
    formatTimestamp,
    searchValue,
    handleSearchInputChange,
    callQueueList,
    dsCallList
  } = usePendingUserQue();
  const wsRef = useRef<WebSocket | null>(null); // WebSocket instance reference
  const isConnected = useRef<boolean>(false); // To track connection status
  const [show, setShow] = useState<boolean>(false);

  const getTimeDifferenceFromNow = (startTime: string | Date) => {
    const start = new Date(startTime).getTime();
    const now = Date.now();
    const difference = now - start;

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    const padWithZero = (unit: number) => (unit < 10 ? `0${unit}` : unit);

    return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(
      seconds
    )}`;
  };

  const columns: ColumnDef<PendingUserQueResult>[] = [
    {
      accessorKey: 'user',
      header: () => 'User',
      cell: ({ row: { original } }) => {
        const {
          event: {
            user: { first_name, last_name }
          }
        } = original;
        return (
          <div className="d-flex align-items-center text-body">
            <Avatar size="l" variant="name">
              {first_name?.charAt(0).toUpperCase() ?? ''}
            </Avatar>
            <h6 className="mb-0 ms-3 text-body">{`${first_name ?? ''} ${
              last_name ?? ''
            }`}</h6>
          </div>
        );
      },
      enableSorting: true,
      meta: {
        headerProps: { style: { minWidth: 160 }, className: 'py-2' }
      }
    },
    {
      accessorKey: 'trip',
      header: 'Trip',
      cell: ({ row: { original } }) => {
        const {
          event: { event_id, navigation_data },
          created_at
        } = original;
        return (
          <Link
            to={`${EventDetailsPage}/${event_id}`}
            className="d-flex align-items-center"
          >
            {navigation_data?.src_place_name && navigation_data?.src_place_name
              ? `Started at ${
                  navigation_data?.src_place_name ?? 'unknown'
                } - Routed
            to ${navigation_data?.dst_place_name ?? 'unknown destination'}`
              : `Trip on ${format(parseISO(created_at), 'MM/dd/yyyy HH:MM')}`}
          </Link>
        );
      },
      meta: {
        headerProps: { style: { width: 200, minWidth: 400 } }
      }
    },
    {
      accessorKey: 'waitTime',
      header: 'Wait Time',
      cell: ({ row: { original } }) => {
        const { call_started_at, wait_time, dispatcher_last_hold } = original;

        if (wait_time) {
          return <span>{wait_time}</span>;
        }

        const [elapsedTime, setElapsedTime] = useState(() =>
          getTimeDifferenceFromNow(dispatcher_last_hold ?? call_started_at)
        );

        useEffect(() => {
          const intervalId = setInterval(() => {
            setElapsedTime(
              getTimeDifferenceFromNow(dispatcher_last_hold ?? call_started_at)
            );
          }, 1000);

          return () => clearInterval(intervalId);
        }, [call_started_at]);

        return <span>{elapsedTime}</span>;
      },
      meta: {
        headerProps: { style: { minWidth: 110 } }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { session_status, call_id } = original;
        const [callDetails] = useState(() =>
          localStorage.getItem('dispatcher_join_call_id')
        );
        const color =
          session_status === 'HOLD'.toUpperCase()
            ? 'danger'
            : callDetails === call_id
            ? 'success'
            : session_status === 'STARTED'.toUpperCase()
            ? 'warning'
            : session_status === 'HOLD'.toUpperCase()
            ? 'danger'
            : 'success';
        const status =
          session_status === 'HOLD'.toUpperCase()
            ? 'ON HOLD'
            : callDetails === call_id
            ? 'JOINED'
            : session_status === 'STARTED'.toUpperCase()
            ? 'IN QUEUE WAITING'
            : session_status === 'HOLD'.toUpperCase()
            ? 'ON HOLD'
            : 'CURRENT CALL';
        return (
          <Badge
            bg={color}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
          >
            {status}
          </Badge>
        );
      },
      meta: {
        headerProps: { className: 'ps-5' },
        cellProps: { className: 'ps-5' }
      }
    },
    {
      accessorKey: 'totalCallTime',
      header: 'Total call Time',
      cell: ({ row: { original } }) => {
        const { call_started_at, session_status, dispatcher_last_hold } =
          original;

        const [elapsedTime, setElapsedTime] = useState(() =>
          getTimeDifferenceFromNow(call_started_at)
        );

        useEffect(() => {
          const intervalId = setInterval(() => {
            setElapsedTime(getTimeDifferenceFromNow(call_started_at));
          }, 1000);

          return () => clearInterval(intervalId);
        }, [call_started_at, session_status, dispatcher_last_hold]);

        return <span>{elapsedTime}</span>;
      },
      meta: {
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end white-space-nowrap' }
      }
    },
    {
      accessorKey: 'callOption',
      enableSorting: false,
      header: 'Call Option',
      cell: ({ row: { original } }) => {
        const {
          session_status,
          session_name,
          call_id,
          dashboard_jwt_token,
          event
        } = original;
        const { event_id } = event;
        const [callDetails] = useState(() =>
          localStorage.getItem('dispatcher_join_call_id')
        );
        const btnText =
          session_status === 'STARTED'.toUpperCase() ? 'ANSWER' : 'RESUME';
        return (
          <div className="position-relative">
            <Button
              variant={
                session_status === 'HOLD'.toUpperCase()
                  ? 'outline-primary'
                  : 'primary'
              }
              className="me-1 fs-10"
              size="sm"
              onClick={() => {
                if (callDetails && session_status !== 'HOLD'.toUpperCase()) {
                  setShow(true);
                } else {
                  localStorage.setItem('dispatcher_join_call_id', call_id);
                  localStorage.setItem('call_active_event_id', event_id);
                  localStorage.setItem(
                    'zoomCallKeys',
                    JSON.stringify({
                      token: dashboard_jwt_token,
                      sessionName: session_name,
                      callIdnumber: call_id
                    })
                  );
                  const sessionOpen = window.open(ZoomCall, '_blank');
                  dispatcher(initialSession(sessionOpen ?? null));
                  // dispatcher(initialSession(sessionOpen ?? null));
                }
              }}
              disabled={
                session_status === 'HOLD' ? false : callDetails === call_id
              }
              // disabled={wait_time ? true : false}
            >
              {btnText}
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { minWidth: 160 }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    },
    {
      accessorKey: 'startCallTime',
      header: 'Start Call Time',
      cell: ({ row: { original } }) => {
        const { call_started_at } = original;
        return formatTimestamp(call_started_at);
      },
      meta: {
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end white-space-nowrap' }
      }
    }
  ];

  const table = useAdvanceTable({
    // data:
    //   dsCallList.results.length > 0
    //     ? dsCallList.results
    //     : callQueueList.results,
    data: dsCallList.results ?? [],
    columns,
    pageSize: 20,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination,
    pageCount: dsCallList.pages,
    // pageCount:
    //   dsCallList.results.length > 0 ? dsCallList?.pages : callQueueList.pages,
    manualPagination: true,
    state: {
      pagination
    }
  });

  useEffect(() => {
    // Function to handle storage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'end_call') {
        // Update state when the value in localStorage changes
        setEndedCallId(event.newValue);
      }
    };

    // Add event listener for the storage event
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!endedCallId) {
      const id: any = localStorage.getItem('end_call');
      setEndedCallId(id);
      localStorage.removeItem('dispatcher_join_call_id');
      localStorage.removeItem('call_active_event_id');
      localStorage.removeItem('zoomCallKeys');
      dispatcher(dsCallRemoveFromListUpdate(id));
    } else {
      localStorage.removeItem('dispatcher_join_call_id');
      localStorage.removeItem('call_active_event_id');
      localStorage.removeItem('zoomCallKeys');
      dispatcher(dsCallEnd(''));
      dispatcher(dsCallRemoveFromListUpdate(endedCallId));
    }
  }, [endedCallId]);

  useEffect(() => {
    if (callQueueList.results.length > 0)
      dispatcher(dsCallGetEvent(callQueueList));
  }, [callQueueList]);

  useEffect(() => {
    const socketUrl = `${process.env.REACT_APP_SOCKET_DEV}=${auth.token}`;

    if (auth.user?.user_type !== 'DS') return;
    if (!isConnected.current) {
      wsRef.current = new WebSocket(socketUrl);
      isConnected.current = true;

      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        isConnected.current = true;
      };

      wsRef.current.onmessage = e => {
        const data = JSON.parse(e.data);
        // console.log('wsRef.current.onmessage ===>>> data: ', data);

        /**
         * Handling call detail when socket type is new_call and call_on_hold
         * This call happend when new call request from user and dispatcher join and then make call on hold
         */
        if (data.type === 'new_call' || data.type === 'call_on_hold') {
          dispatcher(dsCallListUpdate(data.call_data));
        }

        /**
         * Handling call detail when socket type is dispatcher_join_call_id
         * This call happends when dispatcher join the call
         */
        const joinedCallId = localStorage.getItem('dispatcher_join_call_id');
        if (data.type === 'dispatcher_joined') {
          if (joinedCallId !== data.call_data.call_id) {
            dispatcher(dsCallDispatcherJoinRemoveData(data.call_data));
          } else {
            const params = {
              type: 'dispatcher_joined',
              info: data.call_data
            };
            dispatcher(dsCallDispatcherJoinUpdateWaitTime(params));
          }
        }

        /**
         * Handling call detail when socket type is call_ended
         * This call happends when call_ended by student or dispatcher user
         */
        if (data.type === 'call_ended') {
          localStorage.removeItem('dispatcher_join_call_id');
          localStorage.removeItem('call_active_event_id');
          localStorage.removeItem('zoomCallKeys');
          localStorage.removeItem('end_call');
          localStorage.removeItem('joined_call');
          dispatcher(dsCallEnd(''));
          dispatcher(dsCallRemoveFromListUpdate(data.call_data.call_id));
        }
      };

      wsRef.current.onerror = error => {
        console.error('WebSocket error:', error);
        isConnected.current = false;
      };

      wsRef.current.onclose = () => {
        console.log('WebSocket disconnected');
        isConnected.current = false;
      };
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        isConnected.current = false; // Ensure connection status is reset
      }
    };
  }, [auth]);

  return (
    <>
      <div id="join-flow">
        <Row className="g-3 mb-4">
          <Col xs="auto" sm={3}>
            <SearchBox
              placeholder="Search user"
              onChange={handleSearchInputChange}
              value={searchValue}
              className="w-100"
            />
          </Col>
        </Row>
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
            }}
            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
          />
          <AdvanceTableFooter
            navBtn
            showViewAllBtn={false}
            count={callQueueList?.count}
            className="mb-3"
          />
        </AdvanceTableProvider>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <span>You are already in a call.</span>
          <span>Please end your current call before joining another.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={() => setShow(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EcomLatestReviewsTable;
