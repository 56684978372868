import {
  // faEdit,
  faTrash,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DealProfileCard from './DealProfileCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DealsOtherInformation from './DealsOtherInformation';
import DealDetailsTab from './deals-details/DealDetailsTab';
import { Col, Modal, Row } from 'react-bootstrap';
import { eventDetailPage } from '../filterEventsData';
import { useState } from 'react';
import { Routes } from 'common/router-name';
import useDetailsHook from './deals-details/useDetailsHook';
import { useAppSelector } from '../../../../redux/hooks';

const DealDetails = () => {
  const authUser = useAppSelector(state => state.auth.user);
  const domain = process.env.REACT_APP_URL;
  const { event_id } = useDetailsHook();
  const [url, setURL] = useState('');
  const [copied, setCopied] = useState(false);
  const [show, setShow] = useState(false);
  const activeCallEventID = localStorage.getItem('call_active_event_id');

  const handleClose = () => {
    setShow(false);
    setCopied(false);
    setURL('');
  };

  const handleShow = (url: string) => {
    setShow(true);
    setURL(url);
  };

  const handleCopy = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <div>
        <PageBreadcrumb items={eventDetailPage} />
        <div className="mb-9">
          <Row className="align-items-center justify-content-between g-3 mb-4">
            <Col md="auto">
              <h2 className="mb-0">Event Details</h2>
              <h4 className="mb-0 pt-2">Event_ID {event_id ?? 'N/A'}</h4>
            </Col>
            <Col md="auto" className="d-flex">
              {/* <Button
                className="px-3 px-sm-5 me-2"
                style={{ backgroundColor: '#9747FF', color: '#ffffff' }}
              >
                END CALL
              </Button> */}
              {activeCallEventID === event_id && (
                <Button
                  className="px-3 px-sm-5 me-2"
                  style={{ backgroundColor: '#F48B8B', color: '#ffffff' }}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    width={10}
                    strokeWidth={0.1}
                    color="#000000"
                    className="me-sm-2"
                  />
                  <span className="d-none d-sm-inline">CALL LIVE NOW</span>
                </Button>
              )}
              {authUser.user_type === 'DA' && (
                <Button
                  variant="phoenix-danger"
                  className="me-2"
                  startIcon={
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                  }
                >
                  <span>Delete Event</span>
                </Button>
              )}
              <Button
                title="copy link"
                variant="phoenix-secondary"
                className="fs-10"
                size="sm"
                onClick={() =>
                  handleShow(`${domain}${Routes.EventDetailsPage}/${event_id}`)
                }
              >
                <FontAwesomeIcon icon={faShareSquare} />
              </Button>
            </Col>
          </Row>
          <Row className="g-4 g-xl-6">
            <Col xl={5} xxl={4}>
              <div className="sticky-leads-sidebar">
                <DealProfileCard className="mb-3" />
                <DealsOtherInformation />
              </div>
            </Col>
            <Col xl={7} xxl={8}>
              <DealDetailsTab />
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>{url}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={copied ? 'success' : 'primary'}
            onClick={() => handleCopy(url)}
            disabled={copied}
          >
            {copied ? 'Copied' : 'Copy URL'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DealDetails;
